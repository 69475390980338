import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./theme-web.css";
import { Row, Container, Col } from "react-bootstrap";
import tagclient from "./assets/images/tagdibox.png";
import lupa from "./assets/images/lupa.png";
import config from "./config_client";
import boxLoad from "./assets/images/box-load.json";
import Lottie from "react-lottie";
import tracking from "./connect/api";
import ResultTrackView from "./ResultTrackView";
import BoxNotFound from "./BoxNotFound";

function App() {
  const [initSearch, setInitSearch] = useState(false);
  const [boxCode, setBoxCode] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [datailSerch, setTracking] = useState(null);
  const [codeNotFound, setCodeNotFound] = useState(false);

  useEffect(() => {
    document.title = config.head.title(config.page.company);
  }, []);

  const fotterinfocomponent = ({ title, text }) => {
    return (
      <div className="dbx-info-box">
        <div className="dbx-info-title">{title}</div>
        <div className="dbx-info-text">{text}</div>
      </div>
    );
  };

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  let os = getMobileOperatingSystem();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: boxLoad,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const searchbox = () => {
    const clean = boxCode.trim();
    if (clean === "") {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
      setInitSearch(true);
    }
  };

  useEffect(() => {
    if (initSearch) {
      let activeApi = setTimeout(() => {
        tracking.find(boxCode).then((response) => {
          if (!response.error) {
            setTracking(response.guia);
          } else {
            setCodeNotFound(true);
          }
        });
      }, 1500);

      return () => {
        clearTimeout(activeApi);
      };
    }
    // eslint-disable-next-line
  }, [initSearch]);

  const diboxweb = () => {
    window.open("https://www.diboxapp.com", "_blank");
  };

  return (
    <div className="App">
      <Container fluid className="p-0 h-100">
        <div
          className="dbx-top-bar"
          style={{ backgroundColor: config.page.color_base }}
        ></div>
        <div className="dbx-logo-position">
          <div className="dbx-logo-box">
            <div className="dbx-size-logo">
              <img
                alt={config.page.company}
                src={config.page.logo}
                className="img-fluid logo-ajust"
              />
            </div>
          </div>
        </div>

        {!initSearch ? (
          <Container className="dbx-container-content">
            <div
              className="dbx-search-container"
              style={{ backgroundColor: config.page.color_search_box }}
            >
              <div className="dbx-input-container">
                <div className="dbx-input-box">
                  <div
                    className="dbx-text-input"
                    style={{ color: config.page.color_titles }}
                  >
                    Seguimiento de encomienda
                  </div>
                  <input
                    type="text"
                    className="dbx-search-input"
                    placeholder={config.page.placeholderInput}
                    onChange={(ev) => setBoxCode(ev.target.value)}
                  />
                </div>
                <div
                  onClick={searchbox}
                  className="dbx-button-search"
                  style={{
                    backgroundColor: config.page.btn_search_footer_color,
                  }}
                >
                  <img
                    alt={config.page.company}
                    src={lupa}
                    className="img-fluid dbx-icon-search"
                  />
                </div>
              </div>
            </div>
            {isEmpty && (
              <div className="error-text">Debe agregar el numero de guia</div>
            )}
          </Container>
        ) : datailSerch !== null ? (
          <ResultTrackView data={datailSerch} />
        ) : !codeNotFound ? (
          <Container className="dbx-container-content ajust-mobile">
            <div className="loader-content">
              <Lottie options={defaultOptions} height={50} width={50} />
              <div>Estamos buscando tu encomienda...</div>
            </div>
          </Container>
        ) : (
          <BoxNotFound />
        )}
      </Container>
      {os !== "unknown" ? null : (
        <footer
          className={"dbx-footer"}
          style={{ backgroundColor: config.page.btn_search_footer_color }}
        >
          <Row>
            <Col lg={3} xs={6} className="dbx-company-position">
              <div className="dbx-name-company">{config.page.company}</div>
              <div className="dbx-tag-client" onClick={diboxweb}>
                <img
                  alt="Cliente gestionado por Dibox Platform"
                  src={tagclient}
                />
              </div>
            </Col>
            <Col lg={9} xs={6} className="dbx-info-footer">
              {config.page.address !== null &&
                fotterinfocomponent({
                  title: "Dirección",
                  text: config.page.address,
                })}
              {config.page.email !== null &&
                fotterinfocomponent({
                  title: "Correo",
                  text: config.page.email,
                })}
              {config.page.phone !== null &&
                fotterinfocomponent({
                  title: "Teléfono",
                  text: config.page.phone,
                })}
            </Col>
          </Row>
        </footer>
      )}
    </div>
  );
}

export default App;
