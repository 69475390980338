import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import pin from './assets/images/pin.png';
import arrowUp from './assets/images/up-arrow.png';
import config from './config_client';

export default function ResultTrackView({ data }) {

    const [listData, setListData] = useState([]);
    const [boxCode, setBoxCode] = useState(null);

    useEffect(() => {
        setListData(data.tracking.reverse());
        let code = `${data.prefijo_guia || config.page.prefijo}-${data.guia_paquete}`;
        setBoxCode(code)
    },[data])

    const reload = () => {
        window.location = config.page.path;
    }

    const boxRender = (item, index) => (
        <div key={index} className={`box-list ${ index === 0 && 'current-state'}`}>
            <div className="box-icon">
                <img alt={config.page.company} src={index === 0 ? pin : arrowUp} className="current-state-icon" />
            </div>
            <div className="text-track">
                {item.descripcion.toUpperCase()}
                <div className="date-time-track w-100">{index === 0 && 'Estado actual - '}{moment(item.createdAt).format('DD/MM/YYYY')}</div>
            </div>   
        </div>
    )

    return (
        <Container className="dbx-container-content ajust-result-track w-100">
           <div className="w-100 content-list-track">
                <div className="box-list box-guide">Guia consultada: <b className="ml-2">{boxCode}</b></div>
                {listData.map((item, index) => boxRender(item, index))}
                <button onClick={reload} className="back-button box-list" style={{ backgroundColor: config.page.btn_search_footer_color }}>Regresar</button>
           </div>
        </Container>
    )
}
