import React from 'react';
import { Container } from 'react-bootstrap';
import config from './config_client';

export default function BoxNotFound() {
    
    const reload = () => {
        window.location = config.page.path;
    }

    return (
        <Container className="dbx-container-content ajust-mobile">
             <div className="bad-text">Ups! es posible que el numero de guia sea incorrecto</div>
             <button onClick={reload} className="back-button box-list" style={{ backgroundColor: config.page.btn_search_footer_color }}>
                 Buscar de nuevo
             </button>
        </Container>
    )
}
