import logo from "./assets/images/americargo.png";
const config = {
  page: {
    logo: logo,
    color_base: "#0105FF",
    color_titles: "white",
    color_search_box: "#FE0002",
    btn_search_footer_color: "#0105FF",
    btn_search_icon: "",
    path: "http://americargo.cl/",
    company: "Americargo Chile Spa",
    address: "Av. Nueva Providencia 1945, Oficina 702",
    email: null,
    phone: null,
    placeholderInput: "Ingrese guia ej. AMR-8000",
    prefijo: "AMR",
  },
  connect: {
    apiKey: "U2FsdGVkX1+3bodIS8OC7RrCBlLED+dhpLejen3Tme7ShFgMhj86Ccr9Aif53Xgy",
    path: "https://diboxplatform.com:2908/api/v1/tracking",
  },
  head: {
    title: (company) =>
      `Rastrea tu envio - ${company} - Envios a desde Chile hacia Venezuela`,
  },
};

export default config;
